import { createCanvas as universalCreateCanvas } from "./canvas"

type TextMesureContext = {
  measureText: (text: string) => TextMetrics
  font: string
  letterSpacing?: string
}

type Canvas = {
  getContext: (type: "2d") => TextMesureContext | null
}

interface GetTextWidth {
  (
    text: string,
    fontProperties?: FontProps,
    createCanvas?: () => Canvas
  ): number
  canvas?: Canvas
}

export enum FontWeightOptions {
  BOLD = "bold",
  NORMAL = "normal",
}

export enum FontStyleOptions {
  ITALIC = "italic",
  NORMAL = "normal",
}

export enum FontTextTransformOptions {
  UPPERCASE = "uppercase",
  NONE = "none",
}

export type FontProps = {
  size?: string
  family?: string
  weight?: FontWeightOptions
  style?: FontStyleOptions
  lineHeight?: number
  textDecoration?: string
  textTransform?: FontTextTransformOptions
}

const DefaultFontProperities: FontProps = {
  size: "12px",
  family: "Times New Roman",
  weight: FontWeightOptions.NORMAL,
  style: FontStyleOptions.NORMAL,
  lineHeight: 15,
  textTransform: FontTextTransformOptions.NONE,
}

export const textWidth: GetTextWidth = function (
  text: string,
  fontProperties?: FontProps,
  createCanvas: () => Canvas = universalCreateCanvas
) {
  fontProperties = { ...DefaultFontProperities, ...fontProperties }
  const canvas: Canvas = textWidth.canvas || (textWidth.canvas = createCanvas())
  const context = canvas.getContext("2d")

  if (!context) {
    return 0
  }

  let isUppercase = fontProperties.textTransform === "uppercase"

  let fontString = `${fontProperties.weight} ${fontProperties.style} ${fontProperties.size} ${fontProperties.family}`
  context.font = fontString
  const width = context.measureText(
    isUppercase ? text.toUpperCase() : text
  ).width

  return width
}

declare global {
  interface Window {
    getTextWidth: GetTextWidth
  }
}

if (typeof window !== "undefined") {
  window.getTextWidth = textWidth
}
