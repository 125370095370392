import { Extension } from "@tiptap/core"

export const TAB_WIDTH_IN_PX = 36

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textIndent: {
      indentParagraphText: () => ReturnType
      outdentParagraphText: () => ReturnType
    }
  }
}

export const TextIndent = Extension.create({
  name: "textIndent",

  addGlobalAttributes() {
    return [
      {
        types: ["paragraph"],
        attributes: {
          textIndent: {
            default: 0,
            parseHTML: (element) => {
              let maybeTextIndent = element.style.textIndent.split("px")[0]

              return Number(maybeTextIndent || 0) / TAB_WIDTH_IN_PX
            },
            renderHTML: (attributes) => {
              return {
                style: `text-indent: ${
                  (attributes.textIndent || 0) * TAB_WIDTH_IN_PX
                }px`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      indentParagraphText: () => (arg) => {
        let currentIndex = arg.editor.getAttributes("paragraph").textIndent || 0
        arg.commands.updateAttributes("paragraph", {
          textIndent: Math.min(currentIndex + 1, 6),
        })
        return true
      },
      outdentParagraphText: () => (arg) => {
        let currentIndex = arg.editor.getAttributes("paragraph").textIndent || 0

        if (currentIndex === 0) {
          return false
        }
        arg.commands.updateAttributes("paragraph", {
          textIndent: currentIndex - 1,
        })

        return true
      },
    }
  },
})
