import { isEqualCollections } from "helpers/array"
import { ContentInLine } from "models/InstaPageV2/Content"
import { WordChunk } from "./editor-content-to-word-chunk"

export function wordChunksToContentInLines(
  wordChunkLines: WordChunk[][]
): ContentInLine[][] {
  let lines: ContentInLine[][] = []

  for (let [lineIdx, wordChunkLine] of wordChunkLines.entries()) {
    let isLastLine = lineIdx === wordChunkLines.length - 1
    let isNextLineEmpty = wordChunkLines[lineIdx + 1]?.length === 0
    let contentInLine: ContentInLine[] = []

    for (let wordChunk of wordChunkLine) {
      if (wordChunk.type === "indent") {
        contentInLine.push({
          type: "indent",
          text: "",
          width: wordChunk.content[0]?.width || 0,
          isLastLineInParagraph: isLastLine || isNextLineEmpty,
          attrs: wordChunk.content[0]?.attrs,
        })

        continue
      }

      for (let content of wordChunk.content) {
        let previousContent = contentInLine[contentInLine.length - 1]
        let currentMarks = content.marks
        let previousMarks = previousContent?.marks || []

        if (previousContent) {
          let doMarksMatch =
            isEqualCollections(currentMarks, previousMarks) &&
            previousContent.type !== "indent"

          if (doMarksMatch) {
            previousContent.text += content.text || ""
            previousContent.width += content.width
            continue
          }
        }

        contentInLine.push({
          ...content,
          isLastLineInParagraph: isLastLine || isNextLineEmpty,
        })
      }
    }

    lines.push(contentInLine)
  }

  return lines
}
