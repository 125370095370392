import {
  FontProps,
  FontStyleOptions,
  FontTextTransformOptions,
  FontWeightOptions,
} from "lib/text-width"
import { Content } from "models/InstaPageV2/Content"

export default function marksToTextStyle(
  marks: Content["marks"],
  pageStyle: {
    family: string
    lineHeight: number
    size: string
  }
): FontProps {
  let isBold = marks?.some((mark) => mark.type === "bold")
  let isItalic = marks?.some((mark) => mark.type === "italic")
  let isUppercase = marks?.some((mark) => mark.type === "uppercase")
  let localFontSize = marks?.find((m) => m.type === "textStyle")?.attrs
    ?.fontSize

  if (localFontSize) {
    localFontSize = `${localFontSize}px`
  }

  return {
    size: localFontSize || pageStyle.size,
    family: pageStyle.family,
    weight: isBold ? FontWeightOptions.BOLD : FontWeightOptions.NORMAL,
    style: isItalic ? FontStyleOptions.ITALIC : FontStyleOptions.NORMAL,
    textTransform: isUppercase
      ? FontTextTransformOptions.UPPERCASE
      : FontTextTransformOptions.NONE,
  }
}
