export default function linesFromText(
  inputText: string,
  // splitter() returns 'true' when the conditions are met for splitting the text
  splitter: (text: string) => boolean,
  lines: string[] = []
) {
  // Split by words && new line characters, but keep the spaces
  let words: string[] = inputText.match(/\S+|[ \t]+|\n/g) || []

  // if any of the words are too long, split them by character
  if (words.some((word) => splitter(word))) {
    words = words.reduce((acc: string[], word: string) => {
      // If the word is too long, split it by character
      if (splitter(word)) {
        return [...acc, ...word.split("")]
      }
      // Otherwise, just add the word
      return [...acc, word]
    }, [])
  }

  // Find the index for when the splitter() returns true
  const splitWordsIndex = words.findIndex((wordOrChar, index, words) => {
    if (wordOrChar === "\n") {
      return true
    }

    return splitter(words.slice(0, index + 1).join(""))
  })

  if (splitWordsIndex === -1) {
    lines.push(inputText)
    return lines
  }

  if (splitWordsIndex !== 0 && words[splitWordsIndex] === "\n") {
    lines.push(words.slice(0, splitWordsIndex).join(""))

    return linesFromText(
      // Don't pass the '\n' character down to the next recursive loop
      words.slice(splitWordsIndex + 1).join(""),
      splitter,
      lines
    )
  }

  if (splitWordsIndex === 0) {
    let splitWord = words[0] === "\n" ? "" : words[0] || ""
    lines.push(splitWord)

    if (words.length > 1) {
      let splitIndex = words[1] === "\n" ? 2 : 1
      return linesFromText(words.slice(splitIndex).join(""), splitter, lines)
    }

    return lines
  }

  lines.push(words.slice(0, splitWordsIndex).join(""))

  return linesFromText(words.slice(splitWordsIndex).join(""), splitter, lines)
}
