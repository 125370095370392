import { type Canvas } from "canvas"

let log = (msg: string) => {
  if (process.env.NODE_ENV !== "test") {
    console.info(msg)
  }
}

let createCanvas: () => HTMLCanvasElement | Canvas

if (typeof document !== "undefined") {
  log("Using DOM canvas")
  createCanvas = function () {
    return document.createElement("canvas")
  }
} else {
  // This is a very large dependency, so we want to make sure that we do not
  // accidentally load it in the browser bundle
  import("canvas").then((canvas) => {
    log("Using node.js canvas...")

    if (process.env.NODE_ENV === "test") {
      // this is only necessary in the test environment for some reason
      canvas.registerFont("./fonts/Times New Roman.ttf", {
        family: "Times New Roman",
        weight: "normal",
        style: "normal",
      })
    }

    createCanvas = function () {
      // Setting this to 1,1 because it doesn't matter for measuring font width
      return canvas.createCanvas(1, 1)
    }
  })
}

export { createCanvas }
