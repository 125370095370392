import { FontProps, textWidth } from "lib/text-width"
import { Content, ContentInLine } from "models/InstaPageV2/Content"
import contentToWordChunk, {
  WordChunk,
  getWordChunkWidth,
} from "../renderer/editor-content-to-word-chunk"
import { splitWordChunkAtWidth } from "../renderer/split-word-chunks-to-width"
import { wordChunksToContentInLines } from "../renderer/work-chunks-to-content-in-line"
import applyFontVariantToContent from "../renderer/apply-font-variant-to-content"

export type SVGPage = {
  width: number
  lineHeight: number
  fontFamily: string
  fontSize: number
}

export default function contentToLines(
  content: Content[],
  page: SVGPage,
  getTextWidth: (text: string, font: FontProps) => number = textWidth
): ContentInLine[][] {
  const fittableWidth = page.width
  let wordLines: WordChunk[][] = []
  let textPageStyles = {
    family: page.fontFamily,
    lineHeight: page.lineHeight,
    size: `${page.fontSize}px`,
  }

  for (let paragraph of content) {
    let paragraphContent = paragraph.content
    let paragraphTextAlignment = paragraph.attrs?.textAlign
    let paragraphTextIndent = Number(paragraph.attrs?.textIndent || 0)

    if (paragraphTextAlignment) {
      paragraphContent = paragraphContent?.map((content) => {
        content.attrs = {
          textAlign: paragraphTextAlignment || "",
          textIndent: paragraphTextIndent,
        }
        return content
      })
    }

    let currentLine: WordChunk[] = []

    let words = contentToWordChunk(
      applyFontVariantToContent(paragraphContent || [], page.fontSize),
      textPageStyles,
      getTextWidth
    )

    for (let wordChunk of words) {
      let currentLineWidth = getWordChunkWidth(currentLine)
      let wordWidth = getWordChunkWidth(wordChunk)
      let isWordChunkALineBreak = wordChunk.content.some(
        (content) => content.text === "\n"
      )
      let isWordTooLongForWholeLine = wordWidth > fittableWidth

      if (isWordChunkALineBreak) {
        wordLines.push(currentLine)
        currentLine = []
      } else if (isWordTooLongForWholeLine) {
        if (currentLine.length) {
          wordLines.push(currentLine)
          currentLine = []
        }

        splitWordChunkAtWidth(
          wordChunk,
          fittableWidth,
          textPageStyles,
          getTextWidth
        ).forEach((wordChunkForLine) => {
          wordLines.push([wordChunkForLine])
        })
      } else if (currentLineWidth + wordWidth <= fittableWidth) {
        currentLine.push(wordChunk)
      } else {
        let isWordChunkASpace =
          wordChunk.content.length > 0 &&
          (wordChunk.content.every((content) => content.text?.includes(" ")) ||
            wordChunk.type === "indent")

        if (isWordChunkASpace) {
          currentLine.push(wordChunk)
        } else {
          wordLines.push(currentLine)
          currentLine = [wordChunk]
        }
      }
    } // -- END textContent loop

    wordLines.push(currentLine)
  } // -- END paragraph loop

  return wordChunksToContentInLines(wordLines)
}
