import { FontProps, textWidth } from "lib/text-width"
import { Content } from "models/InstaPageV2/Content"
import marksToTextStyle from "./marks-to-text-style"
import { TAB_WIDTH_IN_PX } from "components/item-detail/InstapageV2/TextIndentExtension"

type WordChunkContent = Content & {
  width: number
}

export type WordChunk = {
  type: "word" | "indent"
  content: WordChunkContent[]
}

export default function contentToWordChunk(
  contents: Content[],
  pageStyle: {
    family: string
    lineHeight: number
    size: string
  },
  getTextWidth: (text: string, font: FontProps) => number = textWidth
): WordChunk[] {
  let words: WordChunk[] = []

  let firstChunkIndent = contents[0]?.attrs?.textIndent
  if (firstChunkIndent) {
    words.push({
      type: "indent",
      content: [
        {
          text: "",
          width: TAB_WIDTH_IN_PX * Number(firstChunkIndent),
          attrs: contents[0]?.attrs,
        },
      ],
    })
  }

  for (let content of contents) {
    let contentWords = splitTextIntoWords(content.text || "")

    for (let contentWord of contentWords) {
      contentWord = contentWord.replaceAll("\t", "    ")

      let previousWordChunk = words[words.length - 1]
      let isThisWordASpace = contentWord.startsWith(" ")
      let isThisWordANewLine = contentWord === "\n"
      let precedingWordChunkText = getPrecedingWordChunkText(previousWordChunk)
      let isPreviousWordASpace =
        precedingWordChunkText.endsWith(" ") ||
        previousWordChunk?.type === "indent"
      let isPreviousWordANewLine = precedingWordChunkText === "\n"
      let currentStyles = marksToTextStyle(content.marks, pageStyle)

      if (
        previousWordChunk &&
        !isThisWordASpace &&
        !isThisWordANewLine &&
        !isPreviousWordASpace &&
        !isPreviousWordANewLine
      ) {
        let width = getTextWidth(contentWord, currentStyles)

        previousWordChunk.content.push({ ...content, text: contentWord, width })
        continue
      }

      let width = getTextWidth(contentWord, currentStyles)

      words.push({
        type: "word",
        content: [{ ...content, text: contentWord, width }],
      })
    }
  }

  return words
}

export function getWordChunkWidth(wordChunk: WordChunk | WordChunk[]): number {
  let width = 0
  let chunks = Array.isArray(wordChunk) ? wordChunk : [wordChunk]

  for (let chunk of chunks) {
    for (let content of chunk.content) {
      width += content.width
    }
  }
  return width
}

export function removeTrailingSpaces(wordChunks: WordChunk[]): WordChunk[] {
  let newWordChunks = [...wordChunks]
  while (
    newWordChunks.length > 0 &&
    newWordChunks[newWordChunks.length - 1]?.content[0]?.text?.trim() === ""
  ) {
    newWordChunks.pop()
  }
  return newWordChunks
}

function splitTextIntoWords(text: string): string[] {
  return text.split(/( +)|(\n)/g).filter(Boolean)
}

function getPrecedingWordChunkText(wordChunk: WordChunk | undefined): string {
  if (wordChunk) {
    return wordChunk.content[wordChunk.content.length - 1]?.text || ""
  }

  return ""
}
