import { type FontProps, textWidth } from "lib/text-width"
import linesFromText from "../editor/lines-from-text"
import {
  type WordChunk,
  getWordChunkWidth,
} from "./editor-content-to-word-chunk"
import marksToTextStyle from "./marks-to-text-style"

export function splitWordChunkAtWidth(
  wordChunk: WordChunk,
  width: number,
  pageStyle: {
    family: string
    lineHeight: number
    size: string
  },
  getTextWidth: (text: string, font: FontProps) => number = textWidth
): WordChunk[] {
  let splitWordChunks: WordChunk[] = []

  let currentChunk: WordChunk = { type: "word", content: [] }

  for (let content of wordChunk.content) {
    let prevWidth = getWordChunkWidth(currentChunk)

    if (prevWidth + content.width <= width) {
      currentChunk.content.push(content)
    } else {
      let workingText = content.text || ""
      let textChunks = linesFromText(workingText, (text) => {
        let textWidth = getTextWidth(
          text,
          marksToTextStyle(content.marks, pageStyle)
        )

        return prevWidth + textWidth > width
      })

      let fittableText = textChunks[0] || ""

      if (fittableText) {
        let newWidth = getTextWidth(
          fittableText,
          marksToTextStyle(content.marks, pageStyle)
        )
        currentChunk.content.push({
          ...content,
          text: fittableText,
          width: newWidth,
        })
        splitWordChunks.push(currentChunk)
        currentChunk = { type: "word", content: [] }
        prevWidth = 0
      }

      let remainingText = workingText.slice(fittableText.length)

      textChunks = linesFromText(remainingText, (text) => {
        let textWidth = getTextWidth(
          text,
          marksToTextStyle(content.marks, pageStyle)
        )

        return prevWidth + textWidth > width
      })

      if (textChunks.length) {
        for (let leftOverText of textChunks) {
          currentChunk.content.push({
            ...content,
            text: leftOverText,
            width: getTextWidth(
              leftOverText,
              marksToTextStyle(content.marks, pageStyle)
            ),
          })
        }
      }
    }
  }

  splitWordChunks.push(currentChunk)

  return splitWordChunks
}
