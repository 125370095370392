import { Content } from "models/InstaPageV2/Content"

import splitTextIntoSmallCaps from "./split-text-into-small-caps"

export default function applyFontVariantToContent(
  contentInput: Content[],
  fallBackFontSize: number
): Content[] {
  let result: Content[] = []

  for (let content of contentInput) {
    let isFontVariantMark =
      content.marks?.find((m) => m.type === "textStyle")?.attrs?.fontVariant ===
      "small-caps"

    if (isFontVariantMark) {
      let contentSplitByCasing = splitTextIntoSmallCaps(
        content,
        fallBackFontSize
      )

      result.push(...contentSplitByCasing)

      continue
    }

    result.push(content)
  }

  return result
}
