import { Content } from "models/InstaPageV2/Content"

export const SMALL_CAPS_STEP_DOWN_FONT_SIZE_RATIO = 0.8

export default function splitTextIntoSmallCaps(
  content: Content,
  fallBackFontSize: number
): Content[] {
  let currentFontSize = Number(
    content.marks?.find((m) => m.type === "textStyle")?.attrs?.fontSize ||
      fallBackFontSize
  )

  let stepDownFontSize = currentFontSize * SMALL_CAPS_STEP_DOWN_FONT_SIZE_RATIO

  let result: Content[] = []

  let chars = content.text?.split("") || []

  for (let [idx, char] of chars.entries()) {
    let prevChar = chars[idx - 1]
    let previousContent = result[result.length - 1]
    let isUpperCase = isUpperCaseText(char)
    let isPrevUpperCase = isUpperCaseText(prevChar)

    if (previousContent) {
      let isTheSameCaseAsPrevious =
        (isUpperCase && isPrevUpperCase) || (!isUpperCase && !isPrevUpperCase)

      if (isTheSameCaseAsPrevious) {
        previousContent.text += char.toUpperCase()
        continue
      }
    }

    if (isUpperCase) {
      result.push({ ...content, text: char })
      continue
    }

    // lowercaser
    let charMarks = getUpdatedCharMarks(content.marks, stepDownFontSize)

    result.push({
      attrs: content.attrs,
      type: content.type,
      text: char.toUpperCase(),
      marks: charMarks,
    })
  }

  return result
}

function isUpperCaseText(text: string | undefined): boolean {
  return text ? text === text.toUpperCase() : false
}

function getUpdatedCharMarks(
  marks: Content["marks"],
  fontSize: number
): NonNullable<Content["marks"]> {
  let charMarks = (marks || []).filter((m) => m.type !== "textStyle")
  let existingTextStyleAttrs = marks?.find((m) => m.type === "textStyle")?.attrs

  if (existingTextStyleAttrs) {
    charMarks.push({
      type: "textStyle",
      attrs: {
        ...existingTextStyleAttrs,
        fontSize: String(fontSize),
      },
    })
  } else {
    charMarks.push({
      type: "textStyle",
      attrs: { fontSize: String(fontSize) },
    })
  }

  return charMarks
}
